import { render, staticRenderFns } from "./SampleDetails.vue?vue&type=template&id=204fb996&scoped=true&"
import script from "./SampleDetails.vue?vue&type=script&lang=js&"
export * from "./SampleDetails.vue?vue&type=script&lang=js&"
import style0 from "./SampleDetails.vue?vue&type=style&index=0&id=204fb996&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204fb996",
  null
  
)

export default component.exports