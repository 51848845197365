<template>
  <tr
    class="SampleRow"
    :class="overallClass"
    v-if="!isTender || (isTender && overallClass !== 'ItemClean')">
    <td :class="overallClass">P{{ sampleNumber }}</td>
    <td class="Title">{{ title }}</td>

    <template v-for="group in testGroupValues">
      <td
        v-if="group.id !== 'CP_SCREENING'"
        :key="group.id"
        :class="group.styleClass">
        <span v-if="!group.hasAnyValues">i.a.</span>
        <template
          v-else
          v-for="item in group.items">
          <span
            :key="item.id"
            v-if="item.result !== null && item.result !== 0"
            :class="{ 'BoldText': item.result === 2 }">
            {{ `${
              group.items.length > 1 ? mixWB(item.wordbook) : ''
            } ${
              item.isBelowValue ? decodeURI('%3C') : ''
            } ${
              item.isAboveValue ? decodeURI('%3E') : ''
            } ${
              mixFormatNumber(item.value)
            }` }}
            <br />
          </span>
        </template>
      </td>
    </template>
  </tr>
</template>

<script>
import { testResultsGroups } from '@/globals/javascript/_util/test-results'
import { mapGetters } from 'vuex'

export default {
  name: 'SampleRow-Pre-1.14.0',
  props: {
    sampleNumber: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isTender: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      testResultsGroups,
    }
  },
  computed: {
    ...mapGetters([
      'screeningTestResults',
    ]),
    testResult() {
      if (!this.screeningTestResults) {
        return false
      }
      return this.screeningTestResults.find((x) => x.sampleNumber === this.sampleNumber)
    },
    overallClass() {
      if (!this.testResult) {
        return ''
      }

      if (this.testResult.overallResult === 2 || this.testResult.metalsAboveThreshold) {
        return 'ItemHazardousWaste'
      }

      if (this.testResult.overallResult === 1) {
        return 'ItemContaminated'
      }

      if (this.testResult.overallResult === 0) {
        return 'ItemClean'
      }

      return 'ItemNotAnalysed'
    },
    testGroupValues() {
      if (!this.testResult) {
        return []
      }

      return testResultsGroups.reduce((prev, testGroup) => {
        // Get all tests in groups
        const groupData = testGroup.tests.reduce((prevy, test) => {
          const item = this.testResult.results.find((x) => x.id === test.id)
          item.wordbook = test.wordbook

          // Update group result
          if (item.result !== null) {
            if (prevy.groupResult === null) {
              prevy.groupResult = item.result
            }
            else if (item.result > prevy.groupResult) {
              prevy.groupResult = item.result
            }
          }

          // Update group for having values
          if (!prevy.hasAnyValues && (item.value !== null || item.isTooLowToMeasure)) {
            prevy.hasAnyValues = true
          }

          prevy.items.push(item)
          return prevy
        }, {
          groupResult: null,
          id: testGroup.id,
          items: [],
          hasAnyValues: false,
        })

        // Check for metals above threshold
        if (testGroup.id === '7-metals' && groupData.groupResult !== 2) {
          if (this.testResult.metalsAboveThreshold) {
            groupData.groupResult = 2
          }
        }

        // Add style class to group
        groupData.styleClass = 'ItemNotAnalysed'

        if (groupData.groupResult === 0) {
          groupData.styleClass = 'ItemClean'
        }
        if (groupData.groupResult === 1) {
          groupData.styleClass = 'ItemContaminated'
        }
        if (groupData.groupResult === 2) {
          groupData.styleClass = 'ItemHazardousWaste'
        }

        prev.push(groupData)
        return prev
      }, [])
    },
  },
}
</script>

<style lang="stylus" scoped>
  .SampleRow
    td
      padding 5px 10px
      text-align center
      vertical-align center
      white-space nowrap
      &:nth-child(2)
        white-space normal
    td,
    span
      font-size 0.75rem
    .Title
      text-align left
    .BoldText
      font-weight bold

    td.ItemClean
      background-color $color_report_green
      border-bottom 1px solid $color_report_green_alt
    td.ItemContaminated
      background-color $color_report_yellow
      border-bottom 1px solid $color_report_yellow_alt
    td.ItemHazardousWaste
      background-color $color_report_red
      border-bottom 1px solid $color_report_red_alt
</style>
