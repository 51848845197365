<template>
  <div class="CategoryType">
    <!-- Header -->
    <div class="Header">
      <div class="Title">
        <span class="Category">{{ type.getCategoryTitle() }}</span>
        <span class="Type">{{ getHeaderTitle() }}</span>
      </div>
      <span
        :class="result.class"
        class="Conclusion">{{ result.text }}</span>
    </div>

    <!-- Images -->
    <div class="ImageList">
      <template
        v-for="(image, index) in type.images">
        <div
          v-if="index < 3"
          :key="image.base.path"
          class="Image" >
          <MultiImage
            :image="image" />
        </div>
      </template>
    </div>

    <!-- Type data -->
    <CategoryTypeData :type="type" />

    <!-- Resale -->
    <div
      class="Resale"
      v-if="type.resale === 'yes'">
      <p>{{ mixWB('SUITABLE_FOR_RECYCLING') }}.</p>
    </div>

    <!-- No explanation text -->
    <div v-else-if="workDescription.hideExplanationText"/>

    <!-- Special explanation text -->
    <div
      class="SpecialExplanationText"
      v-else-if="workDescription.specialExplanationText">
      <p>{{ workDescription.specialExplanationText }}</p>
    </div>

    <!-- Sample results and details -->
    <div
      v-else
      class="SampleResultWrap">
      <div
        v-if="sampleNumber"
        class="TableWrap">
        <SampleTable :sampleNumber="sampleNumber" />
      </div>
      <div class="DetailsWrap">
        <SampleDetails
          :materialItem="materialItem"
          :sampleItem="sampleItem"
          :type="type"
          :workDescription="workDescription" />
      </div>
    </div>

    <!-- Work description -->
    <div v-if="type.resale !== 'yes'">
      <WorkDescription :workDescription="workDescription" />
    </div>

    <!-- Back to overview -->
    <a
      href="#assessment-and-recommendation"
      class="BackToOverview"
      @click.prevent="onBackToOverviewClick">{{ mixWB('BACK_TO_THE_OVERVIEW') }}</a>
  </div>
</template>

<script>
import { mixWB } from '@/globals/javascript/_util/mixins'
import MultiImage from '@/components/Images/MultiImage'
import SampleTable from '@/components/PDF/ReportPre1.14.0/SampleTable.vue'
import SampleDetails from '@/components/PDF/ReportPre1.14.0/SampleDetails.vue'
import WorkDescription from '@/components/PDF/ReportPre1.14.0/WorkDescription.vue'
import CategoryTypeData from '@/components/PDF/ReportPre1.14.0/CategoryTypeData.vue'
import { mapGetters } from 'vuex'
import { scrollTo } from '@/globals/javascript/_util/util'

export default {
  name: 'CategoryType-Pre-1.14.0',
  props: {
    type: {
      type: Object,
      required: true,
    },
    typeIndex: {
      type: Number,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    categoryIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'categoryTypes',
      'sampleTypes',
      'screeningSamplesAsObject',
      'workDescriptionCases',
      'materials',
      'selectedBuildings',
    ]),
    categoryType() {
      if (!this.categoryTypes) {
        return false
      }
      return this.categoryTypes.find((x) => x.id === this.type.type.typeID)
    },
    materialItem() {
      if (!this.categoryType) {
        return false
      }

      return this.materials.find((x) => x.id === this.categoryType.materialID) || false
    },
    sampleItem() {
      return this.screeningSamplesAsObject[this.type.id] || false
    },
    sampleNumber() {
      if (!this.sampleItem) {
        return false
      }
      if (this.sampleItem.sampleNumber) {
        return this.sampleItem.sampleNumber
      }
      if (this.sampleItem.equivalentSampleNumber) {
        return this.sampleItem.equivalentSampleNumber
      }
      return false
    },
    workDescription() {
      return this.type.workDescriptionData
    },
    result() {
      if (this.type.resale === 'yes') {
        return {
          class: 'Clean',
          text: mixWB('RESALE'),
        }
      }

      if (!this.workDescription) {
        return {
          class: '',
          text: '?',
        }
      }

      if (this.workDescription.specialResultText) {
        return {
          class: '',
          text: this.workDescription.specialResultText,
        }
      }

      if (!this.workDescription.wdcID) {
        return {
          class: '',
          text: '?',
        }
      }

      let text = this.mixWB(this.workDescriptionCases[this.workDescription.wdcID].wordbook)
      if (
        this.materialItem
        && this.materialItem.isMetalOrIron
        && this.workDescription.wdcID === 'WDC-1'
      ) {
        text = this.mixWB('RECYCLABLE')
      }

      if (this.workDescription.wdcID === 'WDC-1') {
        return {
          text,
          class: 'Clean',
        }
      }
      if (this.workDescription.wdcID === 'WDC-2') {
        return {
          text,
          class: 'Contaminated',
        }
      }
      return {
        text,
        class: 'Hazardous',
      }
    },
  },
  methods: {
    getHeaderTitle() {
      return `${
        this.categoryIndex + 1
      }.${
        this.typeIndex + 1
      } ${
        this.type.getTitle()
      }`
    },
    onBackToOverviewClick() {
      const element = document.getElementById('assessment-and-recommendation')

      if (!element) {
        return
      }

      scrollTo({ element, offset: -60, duration: 500 })
    },
  },
  components: {
    MultiImage,
    SampleTable,
    SampleDetails,
    WorkDescription,
    CategoryTypeData,
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryType
    position relative
    display block
    border 1px solid $color_grey_dark

  .Header
    display flex
    background-color $color_grey_dark
    margin-bottom 15px
    .Title
      flex-grow 2
      padding 5px 15px
      display flex
      justify-content center
      flex-direction column
      span
        color #fff
      .Category
        font-size 0.75rem
      .Type
        font-size 1.125rem
    .Conclusion
      padding 10px
      width 260px
      min-height 50px
      flex-center-children()
      text-align center
      text-transform uppercase
      font-size 1rem
      font-weight bold
      background-color $color_grey_lightest
      &.Clean
        background-color $color_report_green
      &.Contaminated
        background-color $color_report_yellow
      &.Hazardous
        background-color $color_report_red

  .ImageList
    display flex
    justify-content center
    margin-bottom 15px
    .Image
      box(210px)
      margin 0 7.5px
      border 1px solid $color_grey_lightest

  .Resale
    padding 20px
    p
      text-align center
      &:last-child
        padding-bottom 0

  .SpecialExplanationText
    padding 20px
    p
      &:last-child
        padding-bottom 0

  .SampleResultWrap
    display flex
    .TableWrap
      flex-shrink 0
      width 300px
      background-color $color_grey_lightest
      border-right 1px solid $color_grey_lighter
    .DetailsWrap
      flex-grow 2

  .BackToOverview
    position absolute
    bottom -30px
    left 0
    text-decoration underline
    cursor pointer
</style>

<style lang="stylus">
  .CategoryType
    *
      line-height 1.35
</style>
