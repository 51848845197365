<template>
  <div class="SampleTable">
    <span class="Title">{{ mixWB('TEST_RESULTS') }}</span>
    <template v-for="item in tableRows">
      <div
        :key="item.id"
        v-if="!['cpShort', 'cpMedium'].includes(item.id)"
        :class="item.styleClass"
        class="Row">
        <span>{{ mixWB(item.wordbook) }}</span>
        <span v-if="item.isTooLowToMeasure">{{ mixWB('BELOW_THE_LIMIT') }}</span>
        <span v-else-if="!item.value">i.a.</span>
        <span v-else>{{ `${
          item.isBelowValue ? decodeURI('%3C') : ''
        } ${
          item.isAboveValue ? decodeURI('%3E') : ''
        } ${
          mixFormatNumber(item.value)
        } ${ item.unit || '' }` }}</span>
      </div>

    </template>
  </div>
</template>

<script>
import { testResultsForSingleSample } from '@/globals/javascript/_util/test-results'
import { mapGetters } from 'vuex'

export default {
  name: 'SampleTable-Pre-1.14.0',
  props: {
    sampleNumber: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'screeningTestResults',
    ]),
    testResult() {
      if (!this.screeningTestResults) {
        return false
      }
      return this.screeningTestResults.find((x) => x.sampleNumber === this.sampleNumber)
    },
    tableRows() {
      if (!this.testResult) {
        return []
      }

      const items = testResultsForSingleSample.reduce((prev, item) => {
        const test = this.testResult.results.find((x) => x.id === item.id)
        const tableItem = {
          ...test,
          id: item.id,
          styleClass: 'ItemNotAnalysed',
          wordbook: item.wordbook,
        }

        if (tableItem.result === 0) {
          tableItem.styleClass = 'ItemClean'
        }
        if (tableItem.result === 1) {
          tableItem.styleClass = 'ItemContaminated'
        }
        if (tableItem.result === 2) {
          tableItem.styleClass = 'ItemHazardousWaste'
        }

        prev.push(tableItem)
        return prev
      }, [])

      return items
    },
    overallResultData() {
      const data = {
        title: '',
        class: '',
      }

      if (!this.testResult) {
        return data
      }

      if (this.testResult.overallResult === 2) {
        data.title += `${ this.mixWB('HAZARDOUS_WASTE') }`
        data.class = 'ItemHazardousWaste'
        return data
      }

      if (this.testResult.metalsAboveThreshold) {
        data.title += `${ this.mixWB('HAZARDOUS_WASTE') } <br /> (${ this.mixWB('AS_OF_HP14_ECOTOX') })`
        data.class = 'ItemHazardousWaste'
        return data
      }

      if (this.testResult.overallResult === 1) {
        data.title += `${ this.mixWB('CONTAMINATED_WASTE') }`
        data.class = 'ItemContaminated'
        return data
      }

      if (this.testResult.overallResult === 0) {
        data.title += `${ this.mixWB('CLEAN_WASTE') }`
        data.class = 'ItemClean'
        return data
      }

      data.title += `${ this.mixWB('NOT_ANALYSED') }`
      data.class = 'ItemNotAnalysed'
      return data
    },
  },
}
</script>

<style lang="stylus" scoped>
  .SampleTable
    display block

  .Title
    padding 10px
    text-transform uppercase
    text-align center

  .Row
    display flex
    background-color #fff
    border-bottom 1px solid #fff
    &:nth-child(even)
      background-color $color_grey_lighter
      border-bottom 1px solid $color_grey_lighter
    span
      padding 1.5px 10px
      font-size 0.75rem
      &:first-child
        width 120px

    &.ItemClean
      background-color $color_report_green
      border-bottom 1px solid $color_report_green_alt
    &.ItemContaminated
      background-color $color_report_yellow
      border-bottom 1px solid $color_report_yellow_alt
    &.ItemHazardousWaste
      background-color $color_report_red
      border-bottom 1px solid $color_report_red_alt
    &:last-child
      border-bottom none
</style>
