<template>
  <div class="Table">
    <table
      ref="SamplesOverview"
      class="SamplesOverview">

      <!-- Samples -->
      <template v-for="item in mutatedSamples">
        <!-- Sample header -->
        <template v-if="item.isFirstSampleItem">
          <tr
            class="Headline"
            :key="item.sampleNumber">
            <td colspan="2"/>
            <td colspan="4">{{ mixWB('ANALYSIS_RESULTS_MG_KG') }}</td>
          </tr>
          <tr
            class="Legend"
            :key="item.title">
            <td class="SampleNumber">{{ mixWB('SAMPLE_NUMBER_SHORT') }}</td>
            <td class="Comment">{{ mixWB('SAMPLING_LOCATION_AND_COMMENTS') }}</td>
            <td class="PCB">{{ mixWB('SAMPLE_PCB') }}</td>
            <td class="Metals">{{ mixWB('SAMPLE_7_METALS') }}</td>
            <td class="PAH">{{ mixWB('SAMPLE_9PAH') }}</td>
            <td class="Asbestos">{{ mixWB('SAMPLE_ASBEST') }}</td>
          </tr>
        </template>
        <SampleRow
          v-if="item.sampleNumber"
          :key="item.id"
          :sampleNumber="item.sampleNumber"
          :title="item.title"
          :isTender="isTender" />

        <!-- Assess header -->
        <tr
          class="Legend"
          v-if="item.isFirstAssessItem"
          :key="item.sampleNumber">
          <td class="SampleNumber">{{ mixWB('ASSESSMENT_NO') }}</td>
          <td class="Comment">{{ mixWB('SAMPLING_LOCATION_AND_COMMENTS') }}</td>
          <td
            class="Assessment"
            colspan="4">{{ mixWB('ASSESSMENT') }}</td>
        </tr>
        <AssessRow
          v-if="item.assessNumber"
          :key="item.id"
          :item="item" />
      </template>
    </table>
  </div>
</template>

<script>
import SampleRow from '@/components/PDF/ReportPre1.14.0/SampleRow.vue'
import AssessRow from '@/components/PDF/ReportPre1.14.0/AssessRow.vue'

export default {
  name: 'Table-Pre-1.14.0',
  props: {
    samples: {
      type: Array,
      required: true,
    },
    isTender: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    mutatedSamples() {
      const samplesCopy = JSON.parse(JSON.stringify(this.samples))

      // Find first sample item
      const firstSampleItem = samplesCopy.find((x) => x.sampleNumber)
      if (firstSampleItem) {
        firstSampleItem.isFirstSampleItem = true
      }

      // Find first assess sample
      const firstAssessItem = samplesCopy.find((x) => x.assessNumber)
      if (firstAssessItem) {
        firstAssessItem.isFirstAssessItem = true
      }
      return samplesCopy
    },
  },
  components: {
    SampleRow,
    AssessRow,
  },
}
</script>

<style lang="stylus" scoped>
  .Table
    border 1px solid $color_grey_dark

  .SamplesOverview
    border-collapse collapse
    width 100%
    tr
      background-color #fff
      border-bottom 1px solid #fff
      &:nth-child(odd)
        background-color $color_grey_lightest
        border-bottom 1px solid $color_grey_lightest
    td
      padding 5px 10px
      font-size 0.875em
    tr.Headline
      background-color $color_grey_dark
      td
        color #fff
        font-size 1.125rem
        padding 10px
        border-bottom 1px solid $color_grey_dark
        &:last-child
          text-align center
    tr.Legend
      background-color $color_grey_dark
      border-bottom 1px solid $color_grey_dark
      td
        color #fff
        text-align center
        &:nth-child(2)
          text-align left
        &.Assessment
          text-align left

    .SampleNumber
      width 70px
    .PCB
      width 50px
    .Metals
      width 100px
    .PAH
      width 60px
    .Asbestos
      width 60px
</style>
