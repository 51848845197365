<template>
  <tr class="AssessRow">
    <td>V{{ item.assessNumber }}</td>
    <td class="Title">{{ item.title }}</td>
    <td
      class="Reason"
      colspan="4">
      {{ mixGetSampleStatusWordbook(item.status) }}
    </td>
  </tr>
</template>

<script>
export default {
  name: 'AssessRow-Pre-1.14.0',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="stylus" scoped>
  .AssessRow
    td
      padding 5px 10px
      text-align center
      vertical-align center
      white-space nowrap
      border-bottom 1px solid #fff
      font-size 0.75rem
      &:nth-child(2)
        white-space normal
    .Title,
    .Reason
      text-align left
</style>
