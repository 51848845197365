<template>
  <div
    ref="CategoryTypePage"
    class="CategoryTypePage">

    <div class="Page">
      <PageTag
        v-if="typeIndex === 0 && categoryIndex === 0"
        :pageName="mixWB('CATEGORIES_AND_TYPES')" />

      <div
        class="InnerPage js-inner-page">
        <div ref="Content">

          <template v-if="isTender && typeIndex === 0 && categoryIndex === 0">
            <h1
              class="js-headline"
              id="category-list">7. {{ mixWB('ASSESSMENT_AND_RECOMMENDATION') }}</h1>
            <p>
              Herunder kan ses en detaljeret visning af hvert forurenede
              og/eller farlige materiale, samt eventuelle emner til gensalg.
            </p>
            <br/>
          </template>

          <span
            v-if="typeIndex === 0"
            :id="category.id"
            class="Title js-category-title">{{ categoryTitle }}</span>

          <template>
            <CategoryOverview
              :category="category"
              :categoryIndex="categoryIndex"
              :allCategories="allCategories"
              :types="types"
              v-if="typeIndex === 0" />

            <template v-if="!splitIntoTwoPages">
              <CategoryType
                v-if="type"
                :type="type"
                :typeIndex="typeIndex"
                :category="category"
                :categoryIndex="categoryIndex" />
            </template>
          </template>
        </div>
      </div>

      <Footer />
    </div>

    <div
      v-if="splitIntoTwoPages"
      class="Page">
      <div
        class="InnerPage">
        <CategoryType
          v-if="type"
          :type="type"
          :typeIndex="typeIndex"
          :category="category"
          :categoryIndex="categoryIndex" />
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import CategoryOverview from '@/components/PDF/ReportPre1.14.0/CategoryOverview.vue'
import CategoryType from '@/components/PDF/ReportPre1.14.0/CategoryType.vue'
import Footer from '@/components/PDF/Footer.vue'

export default {
  name: 'CategoryTypePage-Pre-1.14.0',
  props: {
    category: {
      type: [Object, Boolean],
      required: true,
    },
    types: {
      type: Array,
      required: false,
      default: () => [],
    },
    typeIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    categoryIndex: {
      type: Number,
      required: true,
    },
    allCategories: {
      type: Array,
      required: true,
    },
    isTender: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      maxPageHeight: 950,
      splitIntoTwoPages: false,
    }
  },
  computed: {
    categoryTitle() {
      return `${ this.categoryIndex + 1 }. ${ this.mixWB(this.category.wordbook) }`
    },
    type() {
      return this.types[this.typeIndex]
    },
  },
  methods: {
    checkTableHeight() {
      if (!this.$refs.Content) {
        setTimeout(() => {
          this.checkTableHeight()
        }, 100)
        return
      }

      const { height } = this.$refs.Content.getBoundingClientRect()

      if (height <= this.maxPageHeight) {
        return
      }

      this.splitIntoTwoPages = true
    },
  },
  components: {
    PageTag,
    CategoryOverview,
    CategoryType,
    Footer,
  },
  mounted() {
    requestAnimationFrame(() => {
      if (this.typeIndex === 0) {
        this.checkTableHeight()
      }
    })
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryTypePage
    display block
  .Page
    page()

  .InnerPage
    innerPageReport()

  .Skipped
    font-style italic

  h1
      innerPageReportH1()

  .Title
    text-transform uppercase
    font-weight bold
    margin-bottom 5px
    font-size 1.125rem
</style>
