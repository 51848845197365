<template>
  <div class="SampleDetails">
    <!-- No sample done -->
    <template v-if="!sampleItem">
      <div
        v-if="autoAssessedText"
        class="NoSample">{{ autoAssessedText }}</div>
      <div
        v-else-if="materialItem && materialItem.isMetalOrIron"
        class="NoSample">
        <p>{{ mixWB('NO_RISK_EXPLANATION_TEXT_METAL') }}</p>
      </div>
      <div
        v-else
        class="NoSample">
        <p>{{ mixWB('NO_RISK_EXPLANATION_TEXT') }}</p>

        <!-- Special rule for tiles -->
        <p v-if="showTileNoAsbestExplanation">
          {{ mixWB('NO_SAMPLE_FOR_ASBESTOS_TILES_TEXT') }}
        </p>

        <!-- Auto assessed text -->
        <p v-if="autoAssessedText">{{ autoAssessedText }}</p>
      </div>
    </template>

    <!-- Assessed -->
    <div
      v-if="sampleItem && sampleItem.assessNumber"
      class="Assessed">
      <p>{{ mixWB('ASSESSED_NO_SAMPLE_REASON_TEXT') }}</p>
      <template v-if="sampleItem.status === 'skip-asbest'">
        <p>{{ mixWB('ASBEST_ASSESSMENT_TEXT') }}</p>
        <span class="Title">{{ mixWB('BASIS_FOR_ASSESSMENT') }}</span>
        <p>
          {{ mixWB('WDC_4_ASSESSMENT_EXPLANATION') }}
        </p>
      </template>
      <template v-if="sampleItem.status === 'skip-hazardous-waste'">
        <template v-if="workDescription.wdcID === 'WDC-3'">
          <p>{{ mixWB('WDC_3_ASSESSMENT_TEXT') }}</p>
          <span class="Title">{{ mixWB('BASIS_FOR_ASSESSMENT') }}</span>
          <p>
            {{ mixWB('WDC_3_ASSESSMENT_EXPLANATION') }}
          </p>
        </template>
        <template v-if="workDescription.wdcID === 'WDC-5'">
          <p>{{ mixWB('WDC_5_ASSESSMENT_TEXT') }}</p>
          <span class="Title">{{ mixWB('BASIS_FOR_ASSESSMENT') }}</span>
          <p>
            {{ mixWB('WDC_5_ASSESSMENT_EXPLANATION') }}
          </p>
        </template>
      </template>

      <p v-if="autoAssessedText">{{ autoAssessedText }}</p>
    </div>

    <!-- Equivalent -->
    <div
      v-if="sampleItem && sampleItem.equivalentNumber"
      class="Equivalent">
      <span>{{ mixWB('CORRESPONDING_SAMPLE') }} P{{ sampleItem.equivalentSampleNumber }}</span>

      <p
        v-if="showTileNoAsbestExplanation"
        class="ExtraExplanation">
        {{ mixWB('NO_SAMPLE_FOR_ASBESTOS_TILES_TEXT') }}
      </p>

      <!-- Auto assessed text -->
      <p
        v-if="autoAssessedText"
        class="ExtraExplanation">{{ autoAssessedText }}</p>
    </div>

    <!-- Sample -->
    <div
      class="Sample"
      v-if="sampleItem && sampleItem.sampleNumber">
      <span class="Title">{{ mixWB('SAMPLE_NUMBER_SHORT') }} P{{ sampleItem.sampleNumber }}</span>
      <div class="ImageList">
        <div class="Image">
          <MultiImage
            :image="sampleItem.images.close" />
        </div>
        <div class="Image">
          <MultiImage
            :image="sampleItem.images.away" />
        </div>
      </div>
      <p
        v-if="showTileNoAsbestExplanation"
        class="ExtraExplanation">
        {{ mixWB('NO_SAMPLE_FOR_ASBESTOS_TILES_TEXT') }}
      </p>
      <!-- Auto assessed text -->
      <p
        v-if="autoAssessedText"
        class="ExtraExplanation">{{ autoAssessedText }}</p>
    </div>
  </div>
</template>

<script>
import MultiImage from '@/components/Images/MultiImage'

export default {
  name: 'SampleDetails-Pre-1.14.0',
  props: {
    type: {
      type: Object,
      required: true,
    },
    workDescription: {
      type: Object,
      required: true,
    },
    sampleItem: {
      type: [Object, Boolean],
      required: true,
    },
    materialItem: {
      type: [Object, Boolean],
      required: true,
    },
  },
  computed: {
    showTileNoAsbestExplanation() {
      if (
        (this.type.categoryID === 'C-6' || this.type.categoryID === 'C-7')
        && !this.type.samples.sampleIDs.includes('ST-1')
      ) {
        return true
      }

      return false
    },
    autoAssessedText() {
      let text = ''

      if (this.type.assessments.assessmentIDs.includes('ST-1')) {
        // Text for Perforated ceiling tiles (CT-86)
        if (this.type.type.typeID === 'CT-86') {
          text = this.mixWB('PERFORATED_CEILING_TILES_ASBESTOS_EXPLANATION')
        }
      }

      return text
    },
  },
  components: {
    MultiImage,
  },
}
</script>

<style lang="stylus" scoped>
  .SampleDetails
    height 100%

  .NoSample,
  .Assessed
    padding 20px
    p
      &:last-child
        padding-bottom 0
    .Title
      font-weight bold
      padding-top 5px
      padding-bottom 2.5px

  .Equivalent
    height 100%
    flex-center-children-column()
    justify-content center
    span
      padding 20px
      text-transform uppercase
      text-align center
    .ExtraExplanation
      text-align center
      padding 0 20px 10px

  .Sample
    .Title
      padding 12px
      text-transform uppercase
      text-align center
    .ExtraExplanation
      text-align center
      padding 0 20px 10px

  .ImageList
    display flex
    justify-content center
    .Image
      width calc(50% - 30px)
      margin 0px 7.5px 10px

</style>
