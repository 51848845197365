<template>
  <div class="CategorySection">
    <!-- Categories with types -->
    <template v-if="category.types.length">
      <CategoryTypePage
        v-for="(type, index) in category.types"
        :key="type.id"
        :category="category.category || false"
        :types="category.types"
        :typeIndex="index"
        :categoryIndex="categoryIndex"
        :allCategories="allCategories"
        :isTender="isTender" />
    </template>

  </div>
</template>

<script>
import CategoryTypePage from '@/components/PDF/ReportPre1.14.0/CategoryTypePage.vue'

export default {
  name: 'CategorySection-Pre-1.14.0',
  props: {
    category: {
      type: Object,
      required: true,
    },
    categoryIndex: {
      type: Number,
      required: true,
    },
    allCategories: {
      type: Array,
      required: true,
    },
    isTender: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    CategoryTypePage,
  },
}
</script>

<style lang="stylus" scoped>
  .CategorySection
    display block
</style>
