<template>
  <div
    class="AnalysisResultsOverview">

    <!-- Page one -->
    <div class="Page">
      <PageTag
        :pageName="mixWB('ANALYSIS_RESULTS_OVERVIEW')" />

      <PageLoading
        v-if="isLoading || isRendering"
        :isLoading="isLoading || isRendering" />

      <div
        v-if="!isLoading"
        class="InnerPage js-inner-page">
        <h1
          v-if="pdfType === 'tender'"
          class="js-headline"
          id="analysis-overview">6. {{ mixWB('CONTAMINATED_AND_OR_HAZARDOUS_MATERIALS') }}</h1>
        <h2
          v-if="pdfType === 'report'"
          class="SubTitle">6.2 {{ mixWB('RISK_MATERIALS_AND_ANALYSIS_RESULTS') }}</h2>

        <Table
          ref="PageOneTable"
          :isTender="pdfType === 'tender'"
          :samples="samplesIntoPages.one" />
      </div>
      <Footer />
    </div>

    <!-- Page two -->
    <div
      v-if="samplesIntoPages.two.length"
      class="Page">

      <PageLoading
        v-if="isLoading || isRendering"
        :isLoading="isLoading || isRendering" />

      <div
        v-if="!isLoading"
        class="InnerPage">
        <h2
          v-if="pdfType === 'report'"
          class="SubTitle">{{ mixWB('MATERIAL_SAMPLES_CONTINUED') }}...</h2>

        <Table
          :isTender="pdfType === 'tender'"
          :samples="samplesIntoPages.two" />
      </div>

      <Footer />
    </div>

    <!-- Page three -->
    <div
      v-if="samplesIntoPages.three.length"
      class="Page">

      <PageLoading
        v-if="isLoading || isRendering"
        :isLoading="isLoading || isRendering" />

      <div
        v-if="!isLoading"
        class="InnerPage">
        <h2
          v-if="pdfType === 'report'"
          class="SubTitle">{{ mixWB('MATERIAL_SAMPLES_CONTINUED') }}...</h2>

        <Table
          :isTender="pdfType === 'tender'"
          :samples="samplesIntoPages.three" />
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import PageLoading from '@/components/PDF/PageLoading.vue'
import Table from '@/components/PDF/ReportPre1.14.0/Table.vue'
import Footer from '@/components/PDF/Footer.vue'
import EventBus from '@/EventBus'
import { mapGetters } from 'vuex'
import { sortBy } from 'lodash-es'

export default {
  name: 'AnalysisResultsOverview-Pre-1.14.0',
  props: {
    extraBuildingPageCount: {
      type: Number,
      required: false,
      default: 0,
    },
    pdfType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      isRendering: true,
      pageMaxHeight: 900,
      samplesExcludedFromPageOne: 0,
      samplesExcludedFromPageTwo: 0,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningTypesLoaded',
      'currentScreeningSamplesLoaded',
      'currentScreeningTestResultsLoaded',
      'typesWithSamples',
      'screeningSamplesAsObject',
    ]),
    mutatedSamples() {
      const data = {
        samples: [],
        equivalent: [],
        assessed: [],
      }

      this.typesWithSamples.forEach((type) => {
        const sample = this.screeningSamplesAsObject[type.id]

        if (!sample) {
          return
        }

        const typeData = {
          title: '',
          sampleNumber: null,
          assessNumber: null,
          equivalentNumber: null,
          equivalentText: '',
          reason: `${ this.mixWB('ASSESSED') }: `,
          status: '',
          testResults: null,
          isFirstSampleItem: false,
          isFirstAssessItem: false,
        }

        // Done samples
        if (sample.status === 'done') {
          typeData.sampleNumber = sample.sampleNumber
          data.samples.push(typeData)
        }

        // Assessed samples
        if (sample.status.includes('skip-')) {
          typeData.assessNumber = sample.assessNumber
          typeData.reason += this.mixGetSampleStatusWordbook(sample.status)
          typeData.status = sample.status
          data.samples.push(typeData)
        }

        // // Equivalent samples
        // if (sample.status === 'equivalent') {
        //   typeData.title = `T${ sample.equivalentNumber } - `
        //   typeData.equivalentNumber = sample.equivalentNumber
        //   typeData.equivalentText = `P${ sample.equivalentSampleNumber }`
        //   data.equivalent.push(typeData)
        // }

        typeData.title += `${
          type.getTitle({ category: true, details: true })
        }${
          type.getCoatingText() ? ` - ${ type.getCoatingText() }` : ''
        }${
          type.description ? ` - ${ type.description }` : ''
        }`
      })

      data.samples = sortBy(data.samples, ['sampleNumber', 'assessNumber'])

      return data
    },
    numberOfSamples() {
      return this.mutatedSamples.samples.length
    },
    samplesIntoPages() {
      const pages = {
        one: [],
        two: [],
        three: [],
      }

      this.mutatedSamples.samples.forEach((sample, index) => {
        if (index >= this.numberOfSamples - this.samplesExcludedFromPageTwo) {
          pages.three.push(sample)
          return
        }

        if (
          index >= this.numberOfSamples
          - this.samplesExcludedFromPageOne
          - this.samplesExcludedFromPageTwo
        ) {
          pages.two.push(sample)
          return
        }

        pages.one.push(sample)
      })

      return pages
    },
  },
  methods: {
    checkTableHeight() {
      if (!this.$refs.PageOneTable.$refs.SamplesOverview) {
        setTimeout(() => {
          this.checkTableHeight()
        }, 100)
        return
      }

      const { height } = this.$refs.PageOneTable.$refs.SamplesOverview.getBoundingClientRect()

      if (height > this.pageMaxHeight * 2) {
        this.samplesExcludedFromPageTwo += 1
        requestAnimationFrame(() => {
          setTimeout(() => {
            this.checkTableHeight()
          }, 50)
        })
        return
      }

      if (height > this.pageMaxHeight) {
        this.samplesExcludedFromPageOne += 1
        requestAnimationFrame(() => {
          setTimeout(() => {
            this.checkTableHeight()
          }, 50)
        })
        return
      }

      this.isRendering = false

      EventBus.$emit('page-loaded', 'analysisOverview')
    },
  },
  components: {
    PageTag,
    PageLoading,
    Table,
    Footer,
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (
        !this.currentScreeningTypesLoaded
        || !this.currentScreeningSamplesLoaded
        || !this.currentScreeningTestResultsLoaded
      ) {
        return
      }

      clearInterval(waitForLoad)
      this.isLoading = false

      requestAnimationFrame(() => {
        this.checkTableHeight()
      })
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .AnalysisResultsOverview
    display block

  .Page
    page()

  .InnerPage
    innerPageReport()
</style>
