<template>
  <div
    class="CategoriesAndTypes">
    <CategorySection
      v-for="(category, index) in mutatedCategories || categories"
      :key="category.id"
      :category="category"
      :categoryIndex="index"
      :allCategories="mutatedCategories || categories"
      :isTender="pdfType === 'tender'" />
  </div>
</template>

<script>
import { sortBy } from 'lodash-es'
import { mapGetters } from 'vuex'
import CategorySection from '@/components/PDF/ReportPre1.14.0/CategorySection.vue'
import { getWorkDescriptionForType } from '@/globals/javascript/_util/work-descriptions-pre-1.14.0'
import EventBus from '@/EventBus'

export default {
  name: 'CategoriesAndTypes-Pre-1.14.0',
  props: {
    pdfType: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'categories',
      'categoryTypes',
      'currentScreeningData',
      'screeningTypes',
      'screeningTestResults',
      'screeningSamplesAsObject',
    ]),
    mutatedCategories() {
      const categoryList = this.categories.reduce((prev, category) => {
        const item = {
          types: [],
          category,
        }

        // Place category
        if (category.place === 'outside') {
          item.categoryOrder = 1
        }
        else if (category.place === 'inside') {
          item.categoryOrder = 2
        }
        else {
          item.categoryOrder = 3
        }

        // Find types for this category
        const types = this.screeningTypes.filter((x) => x.categoryID === category.id)

        // Find work descriprtion for all types
        const typesToUse = types.reduce((prev, type) => {
          const categoryType = this.categoryTypes.find((x) => x.id === type.type.typeID)
          const sampleItem = this.screeningSamplesAsObject[type.id] || false
          const workDescriptionData = getWorkDescriptionForType({
            type,
            categoryType,
            sampleItem,
          })

          type.workDescriptionData = workDescriptionData

          if (this.isTender && type.workDescriptionData.wdcID === 'WDC-1') {
            return prev
          }

          prev.push(type)
          return prev
        }, [])

        item.types = typesToUse

        if (!item.types.length) {
          if (this.isTender) {
            return prev
          }
        }

        prev.push(item)
        return prev
      }, [])

      return sortBy(categoryList, ['categoryOrder', 'position'])
    },
  },
  components: {
    CategorySection,
  },
  mounted() {
    EventBus.$emit('page-loaded', 'categoryTypes')
  },
}
</script>

<style lang="stylus" scoped>
  .CategoriesAndTypes
    display block
</style>
