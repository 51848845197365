<template>
  <div class="CategoryOverview">
    <div class="TableWrap">
      <div class="Row Legend">
        <span class="ID">{{ mixWB('ID') }}</span>
        <span
          v-if="selectedUnits.length"
          class="Unit">{{ mixWB('UNITS') }}
        </span>
        <span
          v-else
          class="Building">{{ mixWB('BUILDINGS') }}
        </span>
        <span class="Type">{{ mixWB('TYPE') }}</span>
        <span class="Note">{{ mixWB('NOTE') }}</span>
        <span class="Risk">{{ mixWB('RISK_FOR') }}</span>
      </div>
      <div
        class="Row"
        v-for="(type, index) in types"
        :key="type.id">
        <span class="ID">{{ categoryIndex + 1 }}.{{ index + 1 }}</span>
        <span
          v-if="type.unitIDs.length"
          class="Unit">{{ type.getUnitsList() }}</span>
        <span
          v-else
          class="Building">{{ getTypeBuildingNumbers(type) }}</span>
        <span class="Type">{{
          type.getTitle()
        }}{{
          type.getCoatingText() ? ` - ${ type.getCoatingText() }` : ''
        }}</span>

        <span class="Note">{{ type.description || '-' }}</span>
        <span class="Risk">{{ type.getCombinedSampleAndAssessmentList() || '-' }}</span>
      </div>
      <div
        class="Row Connected"
        v-for="type in connectedTypes"
        :key="type.id">
        <span class="ID">-</span>

        <span
          v-if="selectedUnits.length"
          class="Unit">
          {{ type.unitsList }}
        </span>
        <span
          v-else
          class="Building">
          {{ type.buildings }}
        </span>

        <span class="Type">{{ type.type }}</span>
        <span class="Note">{{ type.note }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { orderBy, sortBy } from 'lodash-es'

export default {
  name: 'CategoryOverview-Pre-1.14.0',
  props: {
    category: {
      type: [Object, Boolean],
      required: true,
    },
    categoryIndex: {
      type: Number,
      required: true,
    },
    allCategories: {
      type: Array,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
      'selectedBuildings',
      'categoryTypes',
      'sampleTypes',
      'screeningTypes',
    ]),
    connectedTypes() {
      if (!this.screeningTypes || !this.screeningTypes.length) {
        return []
      }
      if (!this.category || !this.category.connection) {
        return []
      }

      const types = this.screeningTypes.filter(
        (x) => this.category.connection.categoryIDs.includes(x.categoryID)
        && this.category.connection.categoryTypeIDs.includes(x.type.typeID),
      )

      const sortedTypes = orderBy(types, ['categoryID', 'createdAt.seconds'], ['asc', 'asc'])

      const finalList = sortedTypes.reduce((prev, type) => {
        // Find category index
        const connectedCategoryIndex = this.allCategories.findIndex(
          (x) => x.category.id === type.categoryID,
        )
        const connectedCategory = this.allCategories[connectedCategoryIndex].category

        // Find type index
        const connectedTypeIndex = this.allCategories[connectedCategoryIndex].types.findIndex(
          (x) => x.id === type.id,
        )

        let unitsList = ''
        let buildingNumbers = ''
        if (this.selectedUnits.length) {
          unitsList = type.getUnitsList()
        }
        else {
          buildingNumbers = this.getTypeBuildingNumbers(type)
        }

        // Find type
        let typeTitle = type.getTitle()
        if (type.getCoatingText()) {
          typeTitle += ` - ${ type.getCoatingText() }`
        }

        const headerID = `${ connectedCategoryIndex + 1 }.${ connectedTypeIndex + 1 } - ${
          this.mixWB(connectedCategory.wordbook)
        }`

        // Create note
        prev.push({
          id: headerID,
          unitsList,
          buildings: buildingNumbers,
          type: typeTitle,
          note: `${ this.mixWB('LISTED_UNDER') } ${ headerID }`,
        })
        return prev
      }, [])

      return sortBy(finalList, ['id'])
    },
  },
  methods: {
    getTypeBuildingNumbers(type) {
      if (!this.selectedBuildings) {
        return ''
      }

      const sortedList = sortBy(type.buildings)
      const numbers = sortedList.reduce((prev, buildingID) => {
        const building = this.selectedBuildings.find((x) => x.buildingID === buildingID)
        prev.push(building.data.Bygningsnr)
        return prev
      }, [])

      return numbers.join(', ')
    },
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryOverview
    border 1px solid $color_grey_dark
    margin-bottom 20px

  .Row
    display flex
    align-items center
    span
      padding 5px 10px
    &.Connected
      span
        font-style italic
    &:nth-child(even)
      background-color $color_grey_lightest

  .Legend
    background-color $color_grey_dark
    span
      color #fff
      padding 10px

  .ID
    width 100px
    flex-shrink 0
  .Unit,
  .Building
    width 100px
    flex-shrink 0
  .Type
    width 200px
    flex-shrink 0
  .Note
    flex-grow 2
  .Risk
    width 150px
    flex-shrink 0
</style>
