<template>
  <div
    :class="{ LastIsFull: typesToShow.lastIsFull }"
    class="CategoryTypeData">
    <div
      v-if="typesToShow.items.includes('units')"
      class="Item">
      <div
        class="IconWrap">
        <HouseIcon />
      </div>
      <span>
        {{
          type.getUnitsList()
        }}
      </span>
    </div>
    <div
      v-if="typesToShow.items.includes('buildings')"
      class="Item">
      <div
        class="IconWrap">
        <HouseIcon />
      </div>
      <span>{{ mixWB('BUILDING') }} {{ buildingNumbers }}</span>
    </div>
    <div
      v-if="typesToShow.items.includes('places')"
      class="Item">
      <div class="IconWrap Smaller">
        <RoomsIcon />
      </div>
      <span>{{ type.getPlacesList() }}</span>
    </div>
    <div
      v-if="typesToShow.items.includes('coating')"
      class="Item">
      <div class="IconWrap">
        <BrushIcon />
      </div>
      <span>{{ type.getCoatingText() }}</span>
    </div>
    <div
      v-if="typesToShow.items.includes('amount')"
      class="Item">
      <div class="IconWrap">
        <AmountIcon />
      </div>
      <span>{{ type.getAmountText() }}</span>
    </div>
    <div
      v-if="typesToShow.items.includes('note')"
      class="Item">
      <div class="IconWrap">
        <NoteIcon />
      </div>
      <span>{{ type.description }}</span>
    </div>
  </div>
</template>

<script>
import HouseIcon from '@/assets/svg/house.svg?inline'
import RoomsIcon from '@/assets/svg/categories/plinth.svg?inline'
import BrushIcon from '@/assets/svg/brush.svg?inline'
import AmountIcon from '@/assets/svg/amount.svg?inline'
import NoteIcon from '@/assets/svg/notepad.svg?inline'
import { mapGetters } from 'vuex'

export default {
  name: 'CategoryTypeData-Pre-1.14.0',
  props: {
    type: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
      'selectedBuildings',
    ]),
    buildingNumbers() {
      const buildings = this.selectedBuildings.filter(
        (x) => this.type.buildings.includes(x.buildingID),
      )
      return buildings.reduce((prev, building, index) => {
        if (index === 0) {
          prev += building.data.Bygningsnr
          return prev
        }

        if (index === buildings.length - 1) {
          prev = `${ prev } ${ this.mixWB('AND') } ${ building.data.Bygningsnr }`
          return prev
        }

        prev = `${ prev }, ${ building.data.Bygningsnr }`
        return prev
      }, '')
    },
    note() {
      if (!this.type.description) {
        return false
      }

      return this.type.description
    },
    typesToShow() {
      const data = {
        items: [],
        lastIsFull: false,
      }
      if (this.type.getUnitsList()) {
        data.items.push('units')
      }
      if (this.buildingNumbers) {
        data.items.push('buildings')
      }
      if (this.type.getPlacesList()) {
        data.items.push('places')
      }
      if (this.type.getCoatingText()) {
        data.items.push('coating')
      }
      if (this.type.getAmountText()) {
        data.items.push('amount')
      }
      if (this.type.description) {
        data.items.push('note')
      }

      if (data.items.length % 2 === 1) {
        data.lastIsFull = true
      }

      return data
    },
  },
  components: {
    HouseIcon,
    RoomsIcon,
    BrushIcon,
    AmountIcon,
    NoteIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryTypeData
    display flex
    flex-wrap wrap
    background-color $color_grey_lightest
    border-top 1px solid $color_grey_lighter
    .Item
      display flex
      align-items center
      min-height 40px
      padding-left 20px
      width 50%
      border-bottom 1px solid $color_grey_lighter
      span
        line-height 1.3
      &:nth-child(even)
        border-left 1px solid $color_grey_lighter
      .IconWrap
        flex-shrink 0
        box(25px)
        margin-right 10px
        &.Smaller
          padding 2px
    &.LastIsFull
      .Item
        &:last-child
          width 100%
          justify-content center
</style>
