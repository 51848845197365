<template>
  <div
    class="WorkDescription"
    :class="{ MissingData: !workDescription.wdcID && !workDescription.specialResultText }">
    <span class="Title">{{ mixWB('WORK_DESCRIPTION') }}</span>
    <p
      v-for="text in workDescription.texts"
      :key="text"
      v-html="text"/>
  </div>
</template>

<script>
export default {
  name: 'WorkDescription-Pre-1.14.0',
  props: {
    workDescription: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="stylus" scoped>
  .WorkDescription
    display block
    border-top 1px solid $color_grey_dark
    padding 15px 15px 5px
    &.MissingData
      background-color red

  .Title
    text-transform uppercase
    padding-bottom 10px
</style>
